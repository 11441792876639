















































import { Component } from 'vue-property-decorator';
import TableDialogFormComponent from '@/mixins/table-dialog-form-component';
import { UserEntityModel, UserPwdModel, UserQueryModel } from '@/entity-model/user-entity';
import UserService, { UserPwdService } from '@/service/user-v2';
import UserEditComponent from './user-edit.vue';
import UserPwdComponent from './user-pwd.vue';
import { ViewModeType } from '@/model/enum';
import { UserStoreModule } from '@/store/modules/user';

@Component({
    components: {
        'user-edit-dialog': UserEditComponent,
        'user-pwd-dialog': UserPwdComponent
    }
})
export default class UserListComponent extends TableDialogFormComponent<UserEntityModel, UserQueryModel> {
    UserEntityModel = UserEntityModel;
    created() {
        this.initTable({
            service: new UserService(),
            queryModel: new UserQueryModel(),
            tableColumns: UserEntityModel.getTableColumns()
        });
        this.getList();
    }

    resetPwdClick(userModel: UserEntityModel) {
        const userPwdModel = new UserPwdModel();
        userPwdModel.id = userModel.id;
        (this.$refs.userPwdFormDialog as any).dialogOpen(userPwdModel, new UserPwdService(), ViewModeType.UPDATE);
        (this.$refs.userPwdFormDialog as any).dialogTitle = '重置密码';
    }
    formDialogOK() {
        this.getList();
    }

    get IsProject() {
        return !!UserStoreModule.CurrentProject;
    }
}

